import Service from "../Service";
import Vue from "vue";
const resource = "/TransportPrice/";

export default {

    GetZon() {
        return Vue.prototype.$http.defaults.baseURL + resource + "GetZon";
        /*  return Service.post(resource + "GetZon", "", {
             params: { requestID: requestID },
         }); */
    },

    /* GetVehicle() {
        return Vue.prototype.$http.defaults.baseURL + resource + "GetVehicle";
    }, */

    /* GetVehicleCapacity(vehiID = 0, requestID) {
        if (vehiID !== null) {
            return Service.post(resource + "GetVehicleCapacity", "", {
                params: { requestID: requestID, VehiID: vehiID },
            });
        }

    }, */

    /* GetParameters(obj, requestID) {
        return Service.post(resource + "GetParameters", obj, {
            params: { requestID: requestID },
        });
    }, */

    pagination(parameters, requestID) {
        return Service.post(resource + "paginationPrice", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        return Service.post(resource + "savePrice", obj, {
            params: { requestID: requestID },
        });
    },

};